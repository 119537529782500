import React, { useState, useContext } from 'react';
// Import Shared Components
import {
  Alert,
  Card,
  CardGroup,
  Search,
  Loader,
  Title,
  Table,
} from 'shared-components';
// Import Global Components
import ClientErrorComponent from '../../../components/ClientErrorComponent';
// Import filter options for search bar
import FILTER from './filter';
// Import Common Utils
import { get } from '../../../utils/http';
// Import Columns for tables
import { getCardholderColumns } from './components/cardholderColumns';
// Import Local Components
import UpdateCardForm from './components/UpdateCardForm';
// Import Contexts
import { ProgramContext } from '../../../context/ProgramConfigContext';
// Import Page Not Found Route Components
import NotFound from '../../NotFound';

export default () => {
    const initialSearchState = {
    primary_processor_reference: '',
  };

  const updateCardEnabled = useContext(ProgramContext).programContext.card_assign_enabled;
  // Set component states
  // eslint-disable-next-line no-unused-vars
  const [success, setSuccess] = useState(null);
  const [clientError, setClientError] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // Set data states
  const [searchBy, setSearchBy] = useState(FILTER.length === 1? FILTER[0].key: null); // default to single search option if only one available
  const [cardholderDetailsList, setCardholderDetailsList] = useState(null);
  const [card, setCard] = useState(null);
  const [program, setProgram] = useState(null);
  // Search params: PRN
  const [searchParams, setSearchParams] = useState(initialSearchState);

  // Triggers a cardholder search based on params passed in by user
  const searchCardholders = async () => {
    try {
      setError(null)
      setSuccess(null)
      setCard(null)
      setCardholderDetailsList(null)
      setLoading(true);
      const query = new URLSearchParams({
        ...searchParams,
      }).toString();

      const cardholderListData = await get(
        `/api/v1/client_portal/card_issuing/cardholders?${query}`
      );

      if (!cardholderListData.count) {
        setError("PRN not found please try again")
      } else {
        const cardholderList = cardholderListData.data

        const cardholderDetailsList = await getCardholderDetailsData(cardholderList);

        // All cardholders under a PRN are under same program 
        const programId = cardholderDetailsList[0].data.accounts[0].program_id
        
        const {data: program_data} = await get(
          `/api/v1/client_portal/card_issuing/programs/${programId}` 
          );
        
        setProgram(program_data)

        if (program_data.card_assign_enabled) {
          const cards = cardsWithAddress(cardholderDetailsList, program_data)

          if (!cards.length) {
            setError("No active cards for this PRN")
          } else {
            setCardholderDetailsList(cards);
          }
        } else {
          setError(`The program this PRN is under does not have Card Update enabled, please contact your program manager if you would like to enable card update for the following program: ${program_data.name} .`)
        }
      }

    } catch (e) {
      setClientError(true);
    }
    setLoading(false);
  };

  async function getCardholderDetailsData(cardholderList) {
    const requests = cardholderList.map(async cardholder => {
      return get(`/api/v1/client_portal/card_issuing/cardholders/${cardholder.id}`);
    });
    const responses = await Promise.all(requests);
    return responses;
  }

  const handleUpdateSearchParamField = (field, value) => {
    setSearchParams({
      ...initialSearchState,
      [field]: value,
    });
  };

  const cardsWithAddress = (cardholderDetailsList, program) => {
    let cardList = [];
  
    const statusPriority = [
      "shipped",
      "shipping",
      "awaiting_processor",
      "not_active",
      "suspended",
      "lost",
      "stolen",
    ];
  
    for (const { data } of cardholderDetailsList) {
      const { 
        address1, address2, city, country, postal_code, state, 
        date_of_birth, email, first_name, last_name, middle_name, 
        phone, shipping_address, accounts, shipping_method, address_last_updated 
      } = data;
  
      for (const { processor_reference, cards, cardholder_id } of accounts) {
        if (processor_reference !== data.primary_processor_reference) continue;
  
        let selectedCard = null;
  
        for (const { status, number } of cards) {
          if (status === "active") {
            selectedCard = { status, number };
            break;
          }
        }
  
        if (!selectedCard) {
          for (const { status, number } of cards) {
            if (statusPriority.includes(status)) {
              if (!selectedCard || statusPriority.indexOf(status) < statusPriority.indexOf(selectedCard.status)) {
                selectedCard = { status, number };
              }
            }
          }
        }
  
        if (selectedCard) {
          cardList.push({
            address1,
            address2,
            city,
            country,
            postal_code,
            state,
            date_of_birth,
            email,
            first_name,
            last_name,
            middle_name,
            phone,
            shipping_address,
            status: selectedCard.status,
            number: selectedCard.number,
            processor_reference,
            cardholder_id,
            card_program_type: program.program_type,
            shipping_method,
            address_last_updated
          });
        }
      }
    }
  
    return cardList;
  };

  const onCardClick = (rowInfo) => {
    setSuccess(null)
    setCard(rowInfo.original)
  }

  if (loading) return <Loader />;
  if (clientError) return <ClientErrorComponent data={{}} />;
  if (!updateCardEnabled) return <NotFound />;

  return (
    <>
      <CardGroup>
        {success && <Alert type="success">{success}</Alert>}
        {error && <Alert type="danger">{error}</Alert>}
        <Card>
          <Title>Search Cards</Title>
          <Search
            searchOptions={FILTER}
            updateSearchBy={setSearchBy}
            searchBy={searchBy}
            value={searchParams[searchBy]}
            onChange={e =>
              handleUpdateSearchParamField(searchBy, e.target.value)
            }
            onClick={() => searchCardholders()}
          />
        </Card>
        { cardholderDetailsList && 
        <Card>
          <Title>Card Search Results </Title>
            <Table
            columns={getCardholderColumns()}
            data={cardholderDetailsList}
            /* eslint-disable no-unused-vars */
            getTrProps={(state, rowInfo, column) => {
              return {
                style: {
                  cursor: 'pointer',
                },
                className: 'hoverable',
                onClick: () => {
                  onCardClick(rowInfo)
                },
              };
            }}
          />
        </Card>
        }
        { card && !success &&
        <Card>
          <Title>Update Card Information </Title>
          <UpdateCardForm 
            card={card}
            setError={setError}
            cardsWithAddress={cardsWithAddress} 
            setCardholderDetailsList={setCardholderDetailsList} 
            program={program}
            setSuccess={setSuccess}
            setClientError={setClientError}
            setLoading={setLoading}
            searchParams={searchParams}
            getCardholderDetailsData={getCardholderDetailsData}
            />
        </Card>
        }
      </CardGroup>
    </>
  )
}